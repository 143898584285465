import { toShortDistroVersion, toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import React from 'react';
import moment from 'moment';
import { WARN } from '~/features/devices/constants/deviceHealthStates';
import { env } from '~/env';

const { networkCodes } = env.runtimeConfig;

export const renderDescription = (descriptionText, controlDevice) => {
    let lastNetworkReportedCountry;
    let countryCode;
    if (controlDevice.lastNetworkCodeReported) {
        countryCode = controlDevice.lastNetworkCodeReported.split(' ')[0];
        lastNetworkReportedCountry = networkCodes[countryCode] || '';
    }

    let description = `${descriptionText || 'N/A'}\n`
        + `----\n`
        + `h3. *+Device details+*\n`
        + `[Link to device in OTA FE|https://ota.admin-eu.rio.cloud/#devices?hw-variant=&page=1&serial-number-prefix=${controlDevice.serialNumber}]\n\n`
        + `*Linked device:* ${controlDevice.linkedSerial || ''}\n`
        + `*Hardware variant:* ${controlDevice.hwVariant || ''}\n`
        + `*Groups:* ${controlDevice.vehicleGroupNames}\n`
        + `*Base software version [Last reported → Target]:* ${toShortSemanticVersion(
            controlDevice.lastBaseSoftwareVersionReported)} → ${toShortSemanticVersion(
            controlDevice.targetBaseSoftwareRelease)}\n`
        + `*Last check for update:* ${moment(controlDevice.lastCheckForUpdateTimestamp).format(
            'YYYY-MM-DD HH:mm:ss')}\n`
        + `*Current network location:* ${lastNetworkReportedCountry ? (lastNetworkReportedCountry || '') : 'n/a'}\n`
        + `*Mileage (Km):* ${controlDevice.lastMileageReported}\n`
        + `*Description:* ${controlDevice.description || ''}\n`
        + `*Last rollout state:* ${controlDevice.lastRolloutState && controlDevice.lastRolloutState[0].deliverableId}@${toShortSemanticVersion(
            controlDevice.lastRolloutState && controlDevice.lastRolloutState[0].baseSoftwareVersion)}\n`
        + `*Spare part number:* ${controlDevice.vehicleManufacturerSparePartNumber}\n`
        + `*VIN:* ${controlDevice.vin}\n`
        + `*Asset id:* ${controlDevice.assetId || ''}\n`
        + `*Last fleet event:* ${moment(controlDevice.lastFleetEvent).format(
            'YYYY-MM-DD HH:mm:ss')}\n`
        + `*Registration method:* ${controlDevice.registrationMethod}\n`
        + `*Registration time:* ${moment(controlDevice.registrationTimestamp).format(
            'YYYY-MM-DD HH:mm:ss')}\n`
        + `*Boot bank:* ${controlDevice.bootBank}\n`
        + `*Boot counter:* ${controlDevice.bootCounter}\n`
        + `*Health status:* ${controlDevice.status === WARN ? controlDevice.statusInfos.map(statusInfo => {return statusInfo.status + ': ' + statusInfo.description + '\n'}) : 'OK'}\n`
        + `----\n`
        + `h3. *+Packages+*\n`;

    controlDevice.lastPackageVersionsReported.map((item) => {
        description += `*${item.packageId}:* ${item.shortPackageVersion}\n`;
    });
    return description;
};
