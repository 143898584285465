import { createApi } from '@reduxjs/toolkit/query/react';
import { dynamicEnvironmentConfig } from '../../../env/dynamicEnvironmentConfig';
import { restBaseQuery } from '~/api/common/baseQuery';
import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

export const softwareProductsApiSlice = createApi({
    reducerPath: 'softwareProductsApi',
    baseQuery: restBaseQuery(dynamicEnvironmentConfig.backend.SOFTWARE_PRODUCTS_SERVICE),
    endpoints: () => ({}),
    // endpoints: (builder) => ({
    //     createProductSoftware: builder.mutation({
    //         query(arg: QueryArg): BaseQueryArg<BaseQuery> {
    //         },
    //         onQueryStarted: async(sdf, { dispatch, queryFulfilled }) => {
    //             try {
    //                 await queryFulfilled;
    //                 // Force a refetch of the search query
    //                 dispatch(
    //                     softwareProductsApiSlice.util.updateQueryData(
    //                         'createProductSoftware',
    //                         undefined,
    //                         (draft) => {
    //                             return [];
    //                         }
    //                     )
    //                 );
    //                 dispatch(
    //                     softwareProductsApiSlice.util.updateQueryData(
    //                         'searchProductSoftware',
    //                         undefined,
    //                         (draft) => {
    //                             return [];
    //                         }
    //                     )
    //                 );
    //             } catch (error) {
    //                 console.error(error);
    //             }
    //
    //         },
    //     }),
    // })
    // },
});

export const {
    reducer: softwareProductsReducer,
    reducerPath: softwareProductsReducerPath,
    middleware: softwareProductsMiddleware,
} = softwareProductsApiSlice;
