import map from 'lodash/fp/map';
import reverse from 'lodash/fp/reverse';
import values from 'lodash/fp/values';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { releaseStates } from '~/features/base/constants/releaseStates';
import { FAILED, SUCCESS } from '~/features/updateEvents/constants/outcomes';
import compact from 'lodash/fp/compact';
import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';

export const ALL = 'all';

export const DISTRO = 'distro';

export const DEVICE_STATUS_NAME_UPDATES_SUSPENDED = 'updates_suspended';
export const DEVICE_STATUS_NAME_OTA_NETWORK = 'ota_network';
export const DEVICE_STATUS_NAME_CAN_MODE = 'can_mode';
export const DEVICE_STATUS_NAME_PROD_MODE = 'prod_mode';

export const DEVICE_STATUS_TYPE_OK = 'OK';
export const DEVICE_STATUS_TYPE_WARN = 'WARN';
export const DEVICE_STATUS_TYPE_ERROR = 'ERROR';

export const DEVICE_TYPE_CM4 = 'DEVICE_TYPE_CM4';
export const IN_CUSTOMER_FLEET = 'IN_CUSTOMER_FLEET';
export const NOT_IN_CUSTOMER_FLEET = 'NOT_IN_CUSTOMER_FLEET';
export const TEST_RELEASES_ACTIVE = 'TEST_RELEASES_ACTIVE';
export const TEST_RELEASES_NOT_ACTIVE = 'TEST_RELEASES_NOT_ACTIVE';

export const UPDATES_ACTIVE = 'UPDATES_ACTIVE';
export const UPDATES_NOT_ACTIVE = 'UPDATES_NOT_ACTIVE';

const allItem = {id: ALL, label: <span data-key={ALL}><FormattedMessage id='intl-msg:all'/></span>, value: ALL};

const toDeliverableOptions = map(deliverable => ({
    id: deliverable.id,
    label: `${deliverable.id} (${deliverable.type})`,
    value: deliverable.id,
    deliverableType: deliverable.type,
}));
const toReleaseStateOptions = map(releaseState => ({
    ...releaseState,
    value: releaseState.id,
    label: <FormattedMessage id={releaseState.label}/>,
}));
const toContextOptions = map(context => ({
    id: context,
    label: context,
    value: context,
}));


const toHwVariantOptions = map(hwVariant => ({
    id: hwVariant,
    label: hwVariant,
    value: hwVariant,
}));
const toVehicleSparePartNumberOptions = map(hwVariant => ({
    id: hwVariant,
    label: hwVariant,
    value: hwVariant,
}));

const toDeliverablesVersionOptions = map(deliverableVersion => ({
    id: deliverableVersion,
    label: deliverableVersion,
    value: deliverableVersion,
}));

export const releaseStateOptions = toReleaseStateOptions(values(releaseStates));
export const releaseStateOptionsWithAll = [allItem, ...releaseStateOptions];
export const createDeliverablesOptions = deliverables => [allItem, ...toDeliverableOptions(deliverables)];

export const deviceTypeOptions = [{
    id: '0',
    label: 'CM4',
    value: 'CM4',
}];

export const deviceTypeByDeliverableOptions = {
    [DELIVERABLE_TYPE_FILE]: [{
        id: '0',
        label: 'CM4',
        value: 'CM4',
    }],
    [DELIVERABLE_TYPE_CM4G]: [{
        id: '0',
        label: 'CM4',
        value: 'CM4',
    }],
    [DELIVERABLE_TYPE_BUNDLE]: [{
        id: '0',
        label: 'CM4',
        value: 'CM4',
    }],
};

export const deviceStatusNameOptions = [allItem, {
    id: '1',
    label: 'UPDATES SUSPENDED',
    value: DEVICE_STATUS_NAME_UPDATES_SUSPENDED,
}, {
    id: '2',
    label: 'OUT OF NETWORK',
    value: DEVICE_STATUS_NAME_OTA_NETWORK,
}, {
    id: '3',
    label: 'CAN MODE',
    value: DEVICE_STATUS_NAME_CAN_MODE,
}, {
    id: '4',
    label: 'PROD MODE',
    value: DEVICE_STATUS_NAME_PROD_MODE,
}];

export const deviceStatusTypeOptionsWithoutAll = [
    {
        id: '1',
        label: DEVICE_STATUS_TYPE_OK,
        value: DEVICE_STATUS_TYPE_OK,
    }, {
        id: '2',
        label: DEVICE_STATUS_TYPE_WARN,
        value: DEVICE_STATUS_TYPE_WARN,
    }, {
        id: '3',
        label: DEVICE_STATUS_TYPE_ERROR,
        value: DEVICE_STATUS_TYPE_ERROR,
    }
]

export const deviceStatusTypeOptions = [allItem, {
    id: '1',
    label: DEVICE_STATUS_TYPE_OK,
    value: DEVICE_STATUS_TYPE_OK,
}, {
    id: '2',
    label: DEVICE_STATUS_TYPE_WARN,
    value: DEVICE_STATUS_TYPE_WARN,
}, {
    id: '3',
    label: DEVICE_STATUS_TYPE_ERROR,
    value: DEVICE_STATUS_TYPE_ERROR,
}];

export const deviceTypeWithAllOptions = [
    allItem,
    {
        id: DEVICE_TYPE_CM4,
        label: 'CM4',
        value: 'CM4',
    }];

export const inCustomerFleetOptions = [allItem, {
    id: IN_CUSTOMER_FLEET,
    label: <FormattedMessage id='intl-msg:inCustomerFleet'/>,
    value: true,
}, {
    id: NOT_IN_CUSTOMER_FLEET,
    label: <FormattedMessage id='intl-msg:notInCustomerFleet'/>,
    value: false,
}];

export const testReleasesActiveOptions = [allItem, {
    id: TEST_RELEASES_ACTIVE,
    label: <FormattedMessage id='intl-msg:testReleasesActive'/>,
    value: true,
}, {
    id: TEST_RELEASES_NOT_ACTIVE,
    label: <FormattedMessage id='intl-msg:testReleasesNotActive'/>,
    value: false,
}];

export const updatesActiveOptions = [allItem, {
    id: UPDATES_NOT_ACTIVE,
    label: <FormattedMessage id='intl-msg:blocked'/>,
    value: false,
}, {
    id: UPDATES_ACTIVE,
    label: <FormattedMessage id='intl-msg:notBlocked'/>,
    value: true,
}];


export const createHwVariantOptions = hwVariants =>
    [allItem, ...toHwVariantOptions(reverse(hwVariants))];

export const createVehicleSparePartNumberOptions = vehicleSparePartNumbers =>
    [allItem, ...toVehicleSparePartNumberOptions(compact(reverse(vehicleSparePartNumbers)))];


export const createContextOptions = (contexts, withAll) => {
    return withAll ? [allItem, ...toContextOptions(contexts)] : [...toContextOptions(contexts)];
};


export const createDeliverablesVersionOptions = packageVersions => [...toDeliverablesVersionOptions(packageVersions)];

// Update events
export const resultOptions = [allItem, {
    id: SUCCESS,
    label: <FormattedMessage id='intl-msg:result.success'/>,
    value: SUCCESS,
}, {
    id: FAILED,
    label: <FormattedMessage id='intl-msg:result.failed'/>,
    value: FAILED,
}];

export const getTimestampSixMonthsAgo = () => new Date().getTime() - 15778800000;

