import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';
import { SoftwareProductPage } from '~/features/softwareProduct/components/SoftwareProductPage';

export const SoftwareProductsRoute = ({ match }) => {

    // send Google Analytics Event
    useDataInterestCleaner();

    return (
        <Switch>
            <Route path={`${match.path}`} component={SoftwareProductPage}/>
            <Redirect to={`${match.url}`}/>
        </Switch>
    );
};

export default SoftwareProductsRoute;
