import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceUpdateEventsContainer from '../DeviceUpdateEventsContainer';
import ResultFormItem from '~/features/base/components/forms/ResultFormItem';
import FromToFormItem from '~/features/base/components/forms/FromToFormItem';
import {
    ALL,
    createDeliverablesOptions,
    createDeliverablesVersionOptions,
} from '~/features/base/constants/filterOptions';
import {
    exportFilteredUpdateEvents,
    fetchFilteredUpdateEvents,
} from '~/features/updateEvents/actions/updateEventActions';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import {
    deviceUpdateEventsLoadingSelector,
    deviceUpdateEventsPageCountSelector,
    deviceUpdateEventsPageNumberSelector,
    deviceUpdateEventsSelector,
    deviceUpdateEventsTotalElementsSelector,
} from '~/features/updateEvents/selectors/updateEventSelectors';
import { showUpdateEventReportDialog } from '~/features/updateEvents/actions/updateEventReportActions';
import { connect } from 'react-redux';
import uid from '~/features/base/utils/uid';
import defaults from 'lodash/fp/defaults';
import { queryToSearchCriteria } from '~/features/updateEvents/utils/updateEventsQuery';
import SuggestFormItem from '~/features/base/components/forms/SuggestFormItem';
import { hotkeys } from 'react-keyboard-shortcuts';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import DeliverableIdFormItem from '~/features/base/components/forms/DeliverableIdFormItem';
import {
    deliverableVersionsSelector,
    groupsDependenciesSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import { fetchDeliverableVersions } from '~/features/deliverables/actions/deliverableActions';

const defaultFilters = {
    deliverableId: undefined,
    targetDeliverableVersion: undefined,
    result: ALL,
    from: undefined,
    to: undefined,
    showOnlyLastEvent: undefined,
};

const FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT = 50;

export class DeviceUpdateEventsDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            filters: defaultFilters,
            page: 1,
        };
        this.updateFilter(defaultFilters);
    }

    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.toggleUpdateEventsDialog();
            },
        },
    };

    setUpdateEventsPage = (page) => {
        this.setState(({
            page: page,
        }));
    };

    renderTitle() {
        return <span>
            <FormattedMessage id='intl-msg:updateEvents'/>
            <span className='padding-left-5'>{this.props.serialNumber}</span>
        </span>;
    }

    renderFilterBar() {
        const {
            deliverables,
            deliverableVersions,
        } = this.props;
        const { filters } = this.state;
        const deliverableOptions = createDeliverablesOptions(deliverables);

        const packageVersionsOptions = createDeliverablesVersionOptions(deliverableVersions);
        return (
            <div className='display-flex row justify-content-center margin-25'>
                <DeliverableIdFormItem inputClassName='filter-input width-300'
                                       value={filters.deliverableId}
                                       options={deliverableOptions}
                                       onChange={this.onDeliverableIdChange}/>
                <div className='padding-left-25'>
                    <SuggestFormItem className='width-300'
                                     disabled={!filters.deliverableId}
                                     value={filters.targetDeliverableVersion}
                                     label='intl-msg:deliverableVersion'
                                     options={packageVersionsOptions}
                                     onChange={this.onTargetDeliverableVersionChange}/>
                </div>
                <div className='padding-left-25'>
                    <ResultFormItem value={filters.result} onChange={this.onResultChange}/>
                </div>
                <div className='padding-left-25'>
                    <FromToFormItem inputClassName='filter-input'
                                    from={filters.from}
                                    to={filters.to}
                                    onChange={this.onFromToChange}/>
                </div>
                <div className='padding-left-25 padding-top-25 '>
                    <Checkbox className='padding-top-5' onClick={this.onShowOnlyLastEventChange}
                              checked={filters.showOnlyLastEvent} size='large'>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:showLatestUpdateEvents.label'/>
                        </label>
                    </Checkbox>
                </div>
                <div className='padding-left-25 padding-top-25'>
                    <div className='pull-right'>
                        <a id='clear-filters-button'
                           className='btn btn-default'
                           onClick={this.clearFilters}>
                            <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                            <FormattedMessage id='intl-msg:clearFilters'/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    renderBody() {
        const {
            serialNumber,
            updateEvents,
        } = this.props;

        return [
            this.renderFilterBar(),
            <DeviceUpdateEventsContainer updateEvents={updateEvents} autoReload={false} showSerialNumber={false}
                                         page={this.state.page}
                                         setUpdateEventsPage={this.setUpdateEventsPage}
                                         serialNumber={serialNumber}/>,
        ];
    }

    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.toggleUpdateEventsDialog}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
            </div>
        );
    }

    onDeliverableIdChange = ({ deliverableId, deliverableType }) => {
        this.updateFilter({
            deliverableId,
            targetDeliverableVersion: undefined,
        });
        if (deliverableId !== undefined) {
            this.props.fetchDeliverablesVersions(deliverableId, deliverableType);
        }
    };
    onTargetDeliverableVersionChange = ({ suggestionId }) => {
        this.updateFilter({
            targetDeliverableVersion: suggestionId || undefined,
        });
    };
    onShowOnlyLastEventChange = (event) => {
        this.updateFilter({
            showOnlyLastEvent: event.currentTarget.checked,
        });
    };
    onResultChange = ({ result }) => {
        this.updateFilter({
            result,
        });
    };
    onFromToChange = ({ from, to }) => {
        this.updateFilter({
            from,
            to,
        });
    };
    clearFilters = () => {
        this.updateFilter({
            ...defaultFilters,
            result: undefined,
        });
    };

    updateFilter(filters) {
        const updatedFilters = {
            ...this.state.filters,
            ...filters,
        };
        this.setState({
            filters: updatedFilters,
        });

        this.setUpdateEventsPage(1);

    }

    handleFilteredUpdateEvents() {
        const { filters, page } = this.state;

        let searchCriteria = defaults(queryToSearchCriteria(filters), {
            hwSerial: this.props.serialNumber,
        });
        searchCriteria.outcome = searchCriteria.outcome !== ALL ? searchCriteria.outcome : undefined;
        const fetchFilteredUpdateEventsOptions = {
            page: 0,
            size: FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT * page,
            searchCriteria,
        };
        this.props.fetchFilteredUpdateEvents(fetchFilteredUpdateEventsOptions);
    }

    render() {
        const { toggleUpdateEventsDialog } = this.props;
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    onHide={toggleUpdateEventsDialog}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
                    bsSize={Dialog.SIZE_FULL_SCREEN}
            />
        );
    }

    componentDidMount() {
        this.handleFilteredUpdateEvents();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { filters, page } = this.state;
        if (prevState.page !== page || prevState.filters !== filters) {
            this.handleFilteredUpdateEvents();
        }
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        deliverables: groupsDependenciesSelector(state),
        deliverableVersions: deliverableVersionsSelector(state),
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        updateEvents: deviceUpdateEventsSelector(state, ownProps),
        updateEventsLoading: deviceUpdateEventsLoadingSelector(state, ownProps),
        pageNumber: deviceUpdateEventsPageNumberSelector(state),
        pageCount: deviceUpdateEventsPageCountSelector(state),
        totalElements: deviceUpdateEventsTotalElementsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchFilteredUpdateEvents: (options) => {
            dispatch(fetchFilteredUpdateEvents(options));
        },
        showUpdateEventReportDialog: (options) => {
            dispatch(showUpdateEventReportDialog(options));
        },
        exportUpdateEvents: (options) => {
            dispatch(exportFilteredUpdateEvents(options));
        },
        fetchDeliverablesVersions: (deliverableId, deliverableType) => {
            dispatch(fetchDeliverableVersions(deliverableId, deliverableType));
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(DeviceUpdateEventsDialog));

DeviceUpdateEventsDialog.defaultProps = {
    // props
    serialNumber: '',
    serialNumberInputVisible: true,
    showOnlyLastEvent: true,
    // functions
    fetchFilteredUpdateEvents: noop,
    toggleUpdateEventsDialog: noop,
    exportUpdateEvents: noop,
};

DeviceUpdateEventsDialog.propTypes = {
    // props
    serialNumber: PropTypes.string,
    serialNumberInputVisible: PropTypes.bool,
    deliverableId: PropTypes.string,
    // functions
    fetchFilteredUpdateEvents: PropTypes.func,
    fetchPackages: PropTypes.func,
    fetchPackageVersions: PropTypes.func,
    toggleUpdateEventsDialog: PropTypes.func,
    exportUpdateEvents: PropTypes.func,
};
