import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterItem } from '~/features/softwareProduct/components/FilterItem';
import { useDispatch } from 'react-redux';
import {
    getSoftwareProductIdFilter, getSoftwareProductSwAssyFilter, getSoftwareProductSwAssyVersionFilter,
    resetSoftwareProductFilter,
    setSoftwareProductId,
    setSoftwareProductSwAssy,
    setSoftwareProductSwAssyVersion,
} from '~/api/softwareProducts/softwareProductsFilterSlice';
import { useAppSelector } from '~/setup/hooks';

export const SoftwareProductsFilter = () => {
    const dispatch = useDispatch();
    const softwareId = useAppSelector(getSoftwareProductIdFilter);
    const swAssy = useAppSelector(getSoftwareProductSwAssyFilter);
    const swAssyVersion = useAppSelector(getSoftwareProductSwAssyVersionFilter);

    return (
        <>
            <div className="display-flex row justify-content-start margin-25">
                <h2>
                    <span className="rioglyph rioglyph-filter padding-bottom-5"></span>
                    <FormattedMessage id="intl-msg:filters"/>
                </h2>
            </div>
            <div className="display-grid grid-cols-4 margin-25">
                <FilterItem
                    label="intl-msg:softwareProducts.softwareId"
                    placeHolder="intl-msg:softwareProducts.softwareId"
                    value={softwareId}
                    onChange={(value) => {
                        dispatch(setSoftwareProductId(value));
                    }}
                />
                <FilterItem
                    label="intl-msg:softwareProducts.swAssy"
                    placeHolder="intl-msg:softwareProducts.swAssy"
                    value={swAssy}
                    onChange={(value) => {
                        dispatch(setSoftwareProductSwAssy(value));
                    }}
                />
                <FilterItem
                    label="intl-msg:softwareProducts.swAssyVersion"
                    placeHolder="intl-msg:softwareProducts.swAssyVersion"
                    value={swAssyVersion}
                    onChange={(value) => {
                        dispatch(setSoftwareProductSwAssyVersion(value));
                    }}
                />
                {/*<FilterItem*/}
                {/*    label="intl-msg:softwareProducts.diagnosticFamily"*/}
                {/*    placeHolder="intl-msg:softwareProducts.diagnosticFamily"*/}
                {/*    value={''}*/}
                {/*    onChange={function(): object {*/}
                {/*        throw new Error('Function not implemented.');*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<FilterItem*/}
                {/*    label="intl-msg:softwareProducts.diagnosticGeneration"*/}
                {/*    placeHolder="intl-msg:softwareProducts.diagnosticGeneration"*/}
                {/*    value={''}*/}
                {/*    onChange={function(): object {*/}
                {/*        throw new Error('Function not implemented.');*/}
                {/*    }}*/}
                {/*/>*/}
                <div
                    className="align-content-end"
                    onClick={() => {
                        dispatch(resetSoftwareProductFilter());
                    }}
                >
                    <label className="text-size-16 padding-bottom-15"><FormattedMessage id="intl-msg:reset"/></label>
                </div>
            </div>
        </>
    );
};
