import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ALL } from '~/features/base/constants/filterOptions';

import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

export class DeliverableIdFormItem extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(item) {
        const deliverableId = item.id === ALL ? undefined : item.value;
        const deliverableType = item.id === ALL ? undefined : item.deliverableType;

        this.props.onChange({
            deliverableId,
            deliverableType
        });
    }

    render() {
        const { label, value, options, inputClassName } = this.props;
        const optionsWithSelection = selectionByValue(value)(options);
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <Select className={inputClassName}
                    options={optionsWithSelection}
                    onChange={this.onChange}/>
            </div>
        );
    }
}

export default DeliverableIdFormItem;

DeliverableIdFormItem.defaultProps = {
    // props
    label: 'intl-msg:deliverableId',
    value: ALL,
    options: [],
    // functions
    onChange: noop,
};

DeliverableIdFormItem.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    inputClassName: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
