import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ListTable from '~/features/base/components/table/ListTable';
import { softwareProductColumnDescriptor } from '~/features/softwareProduct/constants/softwareProductColumnDescriptor';
import size from 'lodash/fp/size';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import {
    DeleteProductSoftwareApiArg,
    ProductSoftwareDto,
    useDeleteProductSoftwareMutation,
} from '~/codegen/softwareProducts';
import { SoftwareProductDialog } from '~/features/softwareProduct/components/SoftwareProductDialog';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

interface SoftwareProductsTableProps {
    isLoading: boolean;
    productSoftwareDtos?: ProductSoftwareDto[];
}

export const SoftwareProductsTable = ({ productSoftwareDtos, isLoading }: SoftwareProductsTableProps) => {
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [selectedSoftwareProduct, setSelectedSoftwareProduct] = useState<ProductSoftwareDto>();

    const [
        deleteProductSoftware,
        { error: deleteProductSoftwareError, isLoading: isDeleteProductSoftwareLoading },
    ] = useDeleteProductSoftwareMutation();

    const onUpdateSoftwareProduct = (id) => {
        const softwareProduct = productSoftwareDtos?.find((currentSoftwareProduct) => currentSoftwareProduct.id === id);
        setSelectedSoftwareProduct(softwareProduct);
        setShowUpdateDialog(true);
    };

    const onPrepareDeleteSoftwareProduct = (id) => {
        const softwareProduct = productSoftwareDtos?.find((currentSoftwareProduct) => currentSoftwareProduct.id === id);
        setSelectedSoftwareProduct(softwareProduct);
        setShowDeleteConfirmationDialog(true);
    };

    const onDeleteSoftwareProduct = async() => {
        const request = { id: selectedSoftwareProduct?.id } as DeleteProductSoftwareApiArg;
        await deleteProductSoftware(request);
        // .unwrap().then(async(result) => {
            // trigger();
            // await dispatch(
            //     enhancedApi.util.updateQueryData(
            //         'searchProductSoftware',
            //         undefined,
            //         (draft) => {
            //             console.log();
            //             // draft.items.remove(result);
            //         },
            //     ),
            // );
        // });
        setSelectedSoftwareProduct(undefined);
        setShowDeleteConfirmationDialog(false);
    };

    const actionsColumn = {
        id: 'actions',
        field: 'id',
        className: 'table-action',
        format: field => {
            const items: object[] = [];
            items.push({
                value: (
                    <div
                        key={'editSoftwareProduct'}
                        data-key={field}
                        onClick={() => onUpdateSoftwareProduct(field)}
                    >
                        <span className="rioglyph rioglyph-pencil margin-right-10"></span>
                        <span><FormattedMessage id="intl-msg:edit"/></span>
                    </div>
                ),
            });
            items.push({
                value: (
                    <div
                        key={'deleteSoftwareProduct'}
                        data-key={field}
                        onClick={() => onPrepareDeleteSoftwareProduct(field)}
                    >
                        <span className="rioglyph rioglyph-trash margin-right-10"></span>
                        <span><FormattedMessage id="intl-msg:delete"/></span>
                    </div>
                ),
            });

            if (size(items)) {
                return (
                    <ButtonDropdown
                        key={'btnDropdown'}
                        title={<span className="rioglyph rioglyph-option-vertical"/>}
                        className="pull-right"
                        bsStyle="link"
                        iconOnly
                        items={items}/>
                );
            }
        },
        formatLabel: () => '',
    };

    const columns = [...softwareProductColumnDescriptor, actionsColumn];
    return (
        <>
            <SoftwareProductDialog
                softwareProduct={selectedSoftwareProduct}
                show={showUpdateDialog}
                onClose={() => setShowUpdateDialog(false)}
            />
            <ConfirmationDialog
                show={showDeleteConfirmationDialog}
                title={<FormattedMessage id="intl-msg:delete"/>}
                content={<FormattedMessage id="intl-msg:softwareProducts.deleteSoftwareProduct"/>}
                bsSize={Dialog.SIZE_SM}
                onClickConfirm={onDeleteSoftwareProduct}
                onClickCancel={() => setShowDeleteConfirmationDialog(false)}
                cancelButtonText={<FormattedMessage id="intl-msg:cancel"/>}
                confirmButtonText={<FormattedMessage id="intl-msg:delete"/>}
                useOverflow
            />
            <ListTable
                isLoading={isLoading}
                items={productSoftwareDtos}
                itemKey="id"
                columnDescriptors={columns}
                allowSelection={false}
                allowSorting={true}
            />
        </>
    );
};
