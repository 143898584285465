import { softwareProductsApiSlice as api } from "../api/softwareProducts/softwareProductsApiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createProductSoftware: build.mutation<
      CreateProductSoftwareApiResponse,
      CreateProductSoftwareApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/software`,
        method: "PUT",
        body: queryArg.productSoftwareDto,
      }),
    }),
    updateProductSoftware: build.mutation<
      UpdateProductSoftwareApiResponse,
      UpdateProductSoftwareApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/software`,
        method: "POST",
        body: queryArg.productSoftwareDto,
      }),
    }),
    searchProductSoftware: build.mutation<
      SearchProductSoftwareApiResponse,
      SearchProductSoftwareApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/software/search`,
        method: "POST",
        body: queryArg.productSoftwareSearchCriteria,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    createSoftwareInstallation: build.mutation<
      CreateSoftwareInstallationApiResponse,
      CreateSoftwareInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/installation/${queryArg.vin}/change`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteProductSoftware: build.mutation<
      DeleteProductSoftwareApiResponse,
      DeleteProductSoftwareApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/software/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type CreateProductSoftwareApiResponse =
  /** status 200 Success */ ProductSoftwareDto;
export type CreateProductSoftwareApiArg = {
  productSoftwareDto: ProductSoftwareDto;
};
export type UpdateProductSoftwareApiResponse =
  /** status 200 Success */ ProductSoftwareDto;
export type UpdateProductSoftwareApiArg = {
  productSoftwareDto: ProductSoftwareDto;
};
export type SearchProductSoftwareApiResponse =
  /** status 200 Success */ PagedModelProductSoftwareDto;
export type SearchProductSoftwareApiArg = {
  /** Number of the page (starting with 0) */
  page: number;
  /** Size of the page */
  size: number;
  productSoftwareSearchCriteria: ProductSoftwareSearchCriteria;
};
export type CreateSoftwareInstallationApiResponse = unknown;
export type CreateSoftwareInstallationApiArg = {
  vin: string;
  body: SoftwareInstallationDto[];
};
export type DeleteProductSoftwareApiResponse = unknown;
export type DeleteProductSoftwareApiArg = {
  id: number;
};
export type Ivd = {
  method: string;
  sw?: string;
  param?: string;
  ecu?: string;
};
export type ProductSoftwareDto = {
  /** date of update, only used for output values */
  id?: number;
  /** Software product as described in PII */
  swAssy: string;
  /** Software product version as described in PII */
  swAssyVersion: string;
  /** softwareId, generated id from swAssy */
  softwareId: string;
  /** diagnostic generation as described in PII */
  diagnosticGeneration: string;
  /** diagnostic family as described in PII */
  diagnosticFamily: string;
  /** the default ivd for those Assy */
  ivd?: Ivd;
  /** date of creation, only used for output values */
  createdAt?: string;
  /** date of update, only used for output values */
  updatedAt?: string;
  /** date of update, only used for output values */
  updatedBy?: string;
};
export type PageMetadata = {
  size?: number;
  number?: number;
  totalElements?: number;
  totalPages?: number;
};
export type PagedModelProductSoftwareDto = {
  content?: ProductSoftwareDto[];
  page?: PageMetadata;
};
export type ProductSoftwareSearchCriteria = {
  /** Software product as described in PII */
  swAssy?: string;
  /** Software product version as described in PII */
  swAssyVersion?: string;
  /** softwareId generated based in swAssy and swAssyVersion */
  softwareId?: string;
};
export type SoftwareInstallationDto = {
  /** vin of the truck updated, only to be used on the response */
  vin?: string;
  /** serial number of the device */
  serialNumber?: string;
  /** cplNo of the software installed */
  cplNo: string;
  /** deliverableId of the software installed */
  softwareId: string;
  /** ivd of the software installed */
  reportedIvd: Ivd;
};
export const {
  useCreateProductSoftwareMutation,
  useUpdateProductSoftwareMutation,
  useSearchProductSoftwareMutation,
  useCreateSoftwareInstallationMutation,
  useDeleteProductSoftwareMutation,
} = injectedRtkApi;
