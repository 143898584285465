import {
    enhancedApi,
    ProductSoftwareSearchCriteria,
    SearchProductSoftwareApiArg,
    useSearchProductSoftwareMutation,
} from '~/codegen/softwareProducts';
import { useEffect, useMemo } from 'react';
import { useAppDispatch } from '~/setup/hooks';

export const useSoftwareProducts = (page: number, size: number, productSoftwareSearchCriteria: ProductSoftwareSearchCriteria) => {
    const [searchProductSoftware, { data, error, isLoading }] = useSearchProductSoftwareMutation();
    const { softwareId, swAssy, swAssyVersion } = productSoftwareSearchCriteria;
    // const dispatch = useAppDispatch();

    const request = {
        page: page,
        size: size,
        productSoftwareSearchCriteria: productSoftwareSearchCriteria,
    } as SearchProductSoftwareApiArg;

    // dispatch(
    //     enhancedApi.util.updateQueryData(
    //         'searchProductSoftware',
    //         // data,
    //         undefined,
    //         (draft) => {
    //             // draft.data.items.push();
    //         },
    //     ),
    // );
    // enhancedApi.enhanceEndpoints({
    //     endpoints: {
    //         createProductSoftware: {
    //             onQueryStarted: async(_, { dispatch, queryFulfilled }) => {
    //                 try {
    //                     await queryFulfilled;
    //                     // Force a refetch of the search query
    //                     dispatch(
    //                         softwareProductsApiSlice.util.resetApiState()
    //                     );
    //                 } catch (error) {
    //                     console.error(error);
    //                 }
    //             },
    //         },
    //         deleteProductSoftware: {
    //             onQueryStarted: async(deleteProductSoftwareApiArg, { dispatch, queryFulfilled }) => {
    //                 try {
    //                     await queryFulfilled;
    //                     // Force a refetch of the search query
    //                     dispatch(
    //                         softwareProductsApiSlice.util.resetApiState()
    //                     );
    //                 } catch (error) {
    //                     console.error(error);
    //                 }
    //             },
    //         },
    //     },
    // });

    // const removeSoftwareProductFromCachedSoftwareProducts = (softwareProductId: string) => {
    //     dispatch(
    //         enhancedApi.util.updateQueryData(
    //             'searchProductSoftware',
    //             undefined,
    //             (draftVehicleList) => {
    //                 draftVehicleList.items = draftVehicleList.items.map((vehicle) => {
    //                     removePaymentMethodFromVehicle(vehicle, paymentMethodId);
    //                     removePaymentMethodFromServiceAssignments(vehicle, paymentMethodId);
    //                     return vehicle;
    //                 });
    //             },
    //         ),
    //     );
    // };



    useEffect(() => {
        searchProductSoftware(request);
    }, [page, size, softwareId, swAssy, swAssyVersion]);

    const softwareProducts = useMemo(
        () => data?.content ?? [],
        [data],
    );

    return {
        softwareProducts,
        isLoading,
        error,
    };
};
