import defaults from 'lodash/fp/defaults';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ENTITY_UPDATE_EVENT_SCOPE_DEVICE } from '~/features/base/constants/entities';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { showUpdateEventReportDialog } from '~/features/updateEvents/actions/updateEventReportActions';
import { exportFilteredUpdateEvents } from '~/features/updateEvents/actions/updateEventActions';

import {
    deviceUpdateEventsLoadingSelector,
    deviceUpdateEventsPageCountSelector,
    deviceUpdateEventsPageNumberSelector,
    deviceUpdateEventsTotalElementsSelector,
} from '~/features/updateEvents/selectors/updateEventSelectors';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

import UpdateEventList from '~/features/updateEvents/components/UpdateEventList';

import { queryToSearchCriteria } from '~/features/updateEvents/utils/updateEventsQuery';
import { parseQuery } from '~/features/base/utils/query';
import uid from '~/features/base/utils/uid';
import { fetchAllDeliverableIds } from '~/features/deliverables/actions/deliverableActions';
import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';

export class DeviceUpdateEventsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    onShowReport = () => {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        this.props.showUpdateEventReportDialog({
            searchCriteria,
            scope: ENTITY_UPDATE_EVENT_SCOPE_DEVICE,
        });
    };

    onExportUpdateEvents = (format = 'csv') => {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        const options = {
            format,
            searchCriteria,
        };
        this.props.exportUpdateEvents(options);
    };

    onLoadMore = () => {
        const { page, setUpdateEventsPage } = this.props;
        setUpdateEventsPage(page + 1);
    };

    getSearchCriteria(parsedQuery) {
        const { serialNumber } = this.props;
        return defaults(queryToSearchCriteria(parsedQuery), {
            hwSerial: serialNumber,
        });
    }

    render() {
        return (
            <UpdateEventList {...this.props}
                             onShowReport={this.onShowReport}
                             onExportUpdateEvents={this.onExportUpdateEvents}
                             onLoadMore={this.onLoadMore}/>
        );
    }

    componentWillMount() {
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_CM4G);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_FILE);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_BUNDLE);

    }

}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        updateEventsLoading: deviceUpdateEventsLoadingSelector(state, ownProps),
        pageNumber: deviceUpdateEventsPageNumberSelector(state),
        pageCount: deviceUpdateEventsPageCountSelector(state),
        totalElements: deviceUpdateEventsTotalElementsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        showUpdateEventReportDialog: (options) => {
            dispatch(showUpdateEventReportDialog(options));
        },
        exportUpdateEvents: (options) => {
            dispatch(exportFilteredUpdateEvents(options));
        },
        fetchFilteredDeliverables: (deliverableType) => {
            dispatch(fetchAllDeliverableIds(
                {
                    page: 0,
                    searchCriteria: {
                        deliverableType: deliverableType,
                    },
                },
            ));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceUpdateEventsContainer);

DeviceUpdateEventsContainer.defaultProps = {
    // props
    serialNumber: '',
    updateEvents: [],
    updateEventsLoading: false,
    showSerialNumber: true,
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    pathname: '',
    search: '',
    // functions
    followRoute: noop,
    exportUpdateEvents: noop,
};

DeviceUpdateEventsContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    updateEvents: PropTypes.array,
    updateEventsLoading: PropTypes.bool,
    showSerialNumber: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalElements: PropTypes.number,
    pathname: PropTypes.string,
    search: PropTypes.string,
    // functions
    followRoute: PropTypes.func,
    exportUpdateEvents: PropTypes.func,
};
