import React from 'react';

export const FILE_DOWNLOAD = 'FILE_DOWNLOAD';
export const DELIVERABLE_UPDATE = 'DELIVERABLE_UPDATE';
export const DELIVERABLE_INSTALL = 'DELIVERABLE_INSTALL';
export const BUNDLE_UPDATE = 'BUNDLE_UPDATE';
export const BUNDLE_INSTALL = 'BUNDLE_INSTALL';

export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const ONGOING = 'ONGOING';

export const ASSIGNED = 'ASSIGNED';
export const SENT_TO_DEVICE = 'SENT_TO_DEVICE';
export const DOWNLOAD_IN_PROGRESS = 'DOWNLOAD_IN_PROGRESS';
export const DOWNLOAD_FINISHED = 'DOWNLOAD_FINISHED';
export const CONFIRMATION_PENDING = 'CONFIRMATION_PENDING'
export const INSTALLATION_IN_PROGRESS = 'INSTALLATION_IN_PROGRESS';
export const INSTALLATION_FINISHED = 'INSTALLATION_FINISHED';
export const REBOOT_PENDING = 'REBOOT_PENDING';
export const PAUSED = 'PAUSED';
export const ABORTED = 'ABORTED';
export const FINISHED = 'FINISHED';

export const updateEventType = {
    [FILE_DOWNLOAD]: {
        id: FILE_DOWNLOAD,
        label: 'intl-msg:deviceUpdateEventType.fileDownload',
        tooltip: 'intl-msg:deviceUpdateEventType.fileDownload.tooltip',
        className: 'label-info',
    },
    [DELIVERABLE_UPDATE]: {
        id: DELIVERABLE_UPDATE,
        label: 'intl-msg:deviceUpdateEventType.deliverableUpdate',
        tooltip: 'intl-msg:deviceUpdateEventType.deliverableUpdate.tooltip',
        className: 'label-info',
    },
    [DELIVERABLE_INSTALL]: {
        id: DELIVERABLE_INSTALL,
        label: 'intl-msg:deviceUpdateEventType.deliverableInstall',
        tooltip: 'intl-msg:deviceUpdateEventType.deliverableInstall.tooltip',
        className: 'label-info',
    },
    [BUNDLE_UPDATE]: {
        id: BUNDLE_UPDATE,
        label: 'intl-msg:deviceUpdateEventType.bundleUpdate',
        tooltip: 'intl-msg:deviceUpdateEventType.bundleUpdate.tooltip',
        className: 'label-info',
    },
    [BUNDLE_INSTALL]: {
        id: BUNDLE_INSTALL,
        label: 'intl-msg:deviceUpdateEventType.bundleInstall',
        tooltip: 'intl-msg:deviceUpdateEventType.bundleInstall.tooltip',
        className: 'label-info',
    },
};

export const updateOutcome = {
    [SUCCESS]: {
        id: SUCCESS,
        label: 'intl-msg:deviceUpdateOutcome.success',
        tooltip: 'intl-msg:deviceUpdateOutcome.success.tooltip',
        className: 'rioglyph rioglyph-ok text-color-success',
    },
    [FAILED]: {
        id: FAILED,
        label: 'intl-msg:deviceUpdateOutcome.failed',
        tooltip: 'intl-msg:deviceUpdateOutcome.failed.tooltip',
        className: 'rioglyph rioglyph-error-sign text-color-danger',
    },
    [ONGOING]: {
        id: ONGOING,
        label: 'intl-msg:deviceUpdateOutcome.ongoing',
        tooltip: 'intl-msg:deviceUpdateOutcome.ongoing.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
    },
    [REBOOT_PENDING]: {
        id: REBOOT_PENDING,
        label: 'intl-msg:report.state.isRebootPending.label',
        tooltip: 'intl-msg:report.state.isRebootPending.label',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
    },
};

export const rolloutStatus = {
    [ASSIGNED]: {
        id: ASSIGNED,
        label: 'intl-msg:deviceRolloutStatus.assigned',
        tooltip: 'intl-msg:deviceRolloutStatus.assigned.tooltip',
        className: 'rioglyph rioglyph-ok text-color-success',
        icon: <i className="rioglyph rioglyph-time"></i>,
    },
    [SENT_TO_DEVICE]: {
        id: SENT_TO_DEVICE,
        label: 'intl-msg:deviceRolloutStatus.sentToDevice',
        tooltip: 'intl-msg:deviceRolloutStatus.sentToDevice.tooltip',
        className: 'rioglyph rioglyph-error-sign text-color-danger',
        icon: <i className="rioglyph rioglyph-envelope"></i>,
    },
    [DOWNLOAD_IN_PROGRESS]: {
        id: DOWNLOAD_IN_PROGRESS,
        label: 'intl-msg:deviceRolloutStatus.downloadInProgress',
        tooltip: 'intl-msg:deviceRolloutStatus.downloadInProgress.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className="rioglyph rioglyph-cloud-download"></i>,
    },
    [DOWNLOAD_FINISHED]: {
        id: DOWNLOAD_FINISHED,
        label: 'intl-msg:deviceRolloutStatus.downloadFinished',
        tooltip: 'intl-msg:deviceRolloutStatus.downloadFinished.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className="rioglyph rioglyph-finish"></i>,
    },
    [INSTALLATION_IN_PROGRESS]: {
        id: INSTALLATION_IN_PROGRESS,
        label: 'intl-msg:deviceRolloutStatus.installationInProgress',
        tooltip: 'intl-msg:deviceRolloutStatus.installationInProgress.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className='rioglyph rioglyph-component-custom-recurrent'></i>,
    },
    [INSTALLATION_FINISHED]: {
        id: INSTALLATION_FINISHED,
        label: 'intl-msg:deviceRolloutStatus.installationFinished',
        tooltip: 'intl-msg:deviceRolloutStatus.installationFinished.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className='rioglyph rioglyph-finish'></i>,
    },
    [REBOOT_PENDING]: {
        id: REBOOT_PENDING,
        label: 'intl-msg:deviceRolloutStatus.rebootPending',
        tooltip: 'intl-msg:deviceRolloutStatus.rebootPending.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className='rioglyph rioglyph-am'></i>,
    },
    [PAUSED]: {
        id: PAUSED,
        label: 'intl-msg:deviceRolloutStatus.paused',
        tooltip: 'intl-msg:deviceRolloutStatus.paused.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className='rioglyph rioglyph-cloud-download'></i>,
    },
    [ABORTED]: {
        id: ABORTED,
        label: 'intl-msg:deviceRolloutStatus.aborted',
        tooltip: 'intl-msg:deviceRolloutStatus.aborted.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className='rioglyph rioglyph-cloud-download'></i>,
    },
    [FINISHED]: {
        id: FINISHED,
        label: 'intl-msg:deviceRolloutStatus.finished',
        tooltip: 'intl-msg:deviceRolloutStatus.finished.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className="rioglyph rioglyph-finish"></i>,
    },
    [FAILED]: {
        id: FAILED,
        label: 'intl-msg:deviceRolloutStatus.failed',
        tooltip: 'intl-msg:deviceRolloutStatus.failed.tooltip',
        className: 'rioglyph rioglyph-refresh spinning text-color-warning',
        icon: <i className="rioglyph rioglyph-cloud-download"></i>,
    },
};
