import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';

import { addLocaleData } from 'react-intl';
// import { addLocaleData } from '@formatjs/intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
// import {de} from '@formatjs/intl-relativetimeformat/locale-data/de.js';
// import {en} from '@formatjs/intl-relativetimeformat/locale-data/en.js';

const DEFAULT_LOCALE = 'en-GB';

// React Intl relies on locale data to support its plural and relative-time
// formatting features, even if your application doesn't support all of these they
// should still be adding all officially supported locales

// TODO: Check whether you added all necessary locales
addLocaleData([
    ...de,
    ...en,
]);

/*
  Locales returned by UserAdmin Service:
  cs-CZ
  da-DK
  de-DE
  en-US
  es-ES
  et-EE
  fr-FR
  it-IT
  lt-LT
  lv-LV
  nl-NL
  no-NO
  pl-PL
  pt-PT
  ro-RO
  sk-SK
  sv-SE
*/
// We're keeping a map of supported locales and the
// default locales for languages without locale for
// being able to tell whether a CDN lookup makes
// sense or not.
// TODO: Remember to add all supported translations for your App
const supportedLocaleMap = {
    de: 'de-DE',
    'de-DE': 'de-DE',
    en: 'en-GB',
    'en-GB': 'en-GB',
};

const extractLanguage = flow(
    defaultTo(DEFAULT_LOCALE),
    split('-'),
    head
);

const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

export {
    DEFAULT_LANG,
    DEFAULT_LOCALE,
    extractLanguage,
    supportedLocaleMap,
};
