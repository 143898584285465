import { createHashHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { activeSagas, setupSagas } from './setupSagas';
import { setupRootReducer } from './setupReducers';
import { setupListeners } from '@reduxjs/toolkit/query';
import { vehicleManagementApi } from '~/api/vehicles/VehicleManagement.api';
import { loggingServiceApi } from '~/api/logs/LoggingService.api';
import { controlDeviceApi } from '~/api/controlDevice/ControlDevice.api';
import { vehicleGroupApi } from '~/api/vehicleGroup/VehicleGroup.api';
import { deliverableManagementApi } from '~/api/deliverables/DeliverablesManagement.api';
import { deviceActionServiceApi } from '~/api/deviceAction/DeviceAction.api';
import { softwareProductsMiddleware } from '~/api/softwareProducts/softwareProductsApiSlice';

//
// Setup for Browser extension "Redux DevTools":
// (`window.devToolsExtension` has been deprecated)
//
// https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm
//
// We want to be able to see what happened if an error occurs in `production`
// mode without disturbing the performance with the console logger that is
// normally running only in `development`. We restrict the information flowing
// through the devtools in `production` mode only.
//
// Docs here https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
//
// const composeEnhancers = composeWithDevTools({
    //actionSanitizer: ({ type }) => ({ type, payload: 'RESTRICTED' }),
    //autoPause: false,
    //features: {
    //    dispatch: false, // dispatch custom actions
    //    export: false, // export history of actions in a file
    //    import: false, // import history of actions from a file
    //    lock: true, // lock/unlock dispatching actions and side effects
    //    pause: true, // start/pause recording of dispatched actions
    //    persist: false, // persist states on page reloading
    //    reorder: true, // drag and drop actions in the history list
    //    skip: false, // skip (cancel) actions
    //    test: false, // generate tests for the selected actions
    //},
    //shouldCatchErrors: false,
    //shouldHotReload: false,
    //shouldRecordChanges: true,
    //stateSanitizer: state => ({ RESTRICTED: true }),
// });

const getUserConfirmation = (message, callback) => callback(window.confirm(message));
const history = createHashHistory({
    getUserConfirmation,
    hashType: 'noslash',
});

const sagaMiddleware = setupSagas();
const rootReducer = setupRootReducer(history);

const middleware = [routerMiddleware(history), sagaMiddleware];

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        // getDefaultMiddleware({
        //     serializableCheck: false, immutableCheck: false,
        // }).concat(
        getDefaultMiddleware().concat(
            middleware,
            vehicleManagementApi.middleware,
            loggingServiceApi.middleware,
            controlDeviceApi.middleware,
            vehicleGroupApi.middleware,
            deliverableManagementApi.middleware,
            deviceActionServiceApi.middleware,
            softwareProductsMiddleware,
        ),
});
export const createStore = () => {
    return store;
};


setupListeners(store.dispatch);
activeSagas(sagaMiddleware);

type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];

export { history, store };
