import React from 'react';
import { FormattedMessage } from 'react-intl';

type FilterItemProps = {
    label: string;
    value: string;
    placeHolder: string;
    onChange: (value: string) => void;
};

export const FilterItem = ({ label, value, placeHolder, onChange }: FilterItemProps) => {
    return (
        <div className="justify-items-center">
            <div className="form-group">
                <label className="control-label">
                    <FormattedMessage id={label}/>
                </label>
                <span className="input-group width-90pct">
                    <span className="input-group-addon">
                        <span className="rioglyph rioglyph-search" aria-hidden="true"></span>
                    </span>
                    <input
                        className="form-control"
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                    />
                </span>
            </div>
        </div>
    );
};
