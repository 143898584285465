import React from 'react';
import { FormattedMessage } from 'react-intl';

type FormItemProps = {
    label: string;
    value: string;
    placeHolder: string;
    onChange: (event) => void;
};

export const FormItem = ({ label, value, placeHolder, onChange }: FormItemProps) => {
    return (
        <div className="justify-items-center">
            <div className="form-group">
                <label className="control-label">
                    <FormattedMessage id={label}/>
                </label>
                <span className="input-group width-100pct">
                    <input
                        className="form-control"
                        type="text"
                        value={value}
                        // placeholder={placeHolder}
                        onChange={onChange}
                    />
                </span>
            </div>
        </div>
    );
};
