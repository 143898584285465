import React, { useEffect } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage } from 'react-intl';
import { FormItem } from '~/features/softwareProduct/components/FormItem';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import {
    CreateProductSoftwareApiArg, enhancedApi,
    ProductSoftwareDto,
    UpdateProductSoftwareApiArg,
    useCreateProductSoftwareMutation, useSearchProductSoftwareMutation,
    useUpdateProductSoftwareMutation,
} from '~/codegen/softwareProducts';
import { Controller, useForm } from 'react-hook-form';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import { useAppDispatch } from '~/setup/hooks';

type SoftwareProductDialogProps = {
    show: boolean;
    softwareProduct?: ProductSoftwareDto;
    onClose: () => void;
};

export const SoftwareProductDialog = ({ show, softwareProduct, onClose }: SoftwareProductDialogProps) => {
    const dispatch = useAppDispatch();
    const [
        createProductSoftware,
        { error: createProductSoftwareError, isLoading: isCreateProductSoftwareLoading },
    ] = useCreateProductSoftwareMutation();
    const [
        updateProductSoftware,
        { error: updateProductSoftwareError, isLoading: isUpdateProductSoftwareLoading },
    ] = useUpdateProductSoftwareMutation();
    const isLoading = isCreateProductSoftwareLoading || isUpdateProductSoftwareLoading;

    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,
    } = useForm();

    const handleCloseDialog = () => {
        onClose();
        reset();
    };

    const onSubmit = async(data) => {
        if (isEmpty(errors)) {
            // check if create or update
            if (isEmpty(softwareProduct)) {
                const request = {
                    productSoftwareDto: {
                        swAssy: data.swAssy,
                        swAssyVersion: data.swAssyVersion,
                        softwareId: data.softwareId,
                        diagnosticGeneration: data.diagnosticGeneration,
                        diagnosticFamily: data.diagnosticFamily,
                    },
                } as CreateProductSoftwareApiArg;

                const result = createProductSoftware(request);
                //     .unwrap()
                //     .then((result) => {
                //         // dispatch(
                //         //     enhancedApi.util.updateQueryData(
                //         //         'searchProductSoftware',
                //         //         undefined,
                //         //         (draft) => {
                //         //             draft.items.push(result);
                //         //         },
                //         //     ),
                //         // );
                //     });

                // if ('error' in result) {
                //     return;
                // }
            } else {
                const request = {
                    productSoftwareDto: {
                        swAssy: data.swAssy,
                        swAssyVersion: data.swAssyVersion,
                        softwareId: data.softwareId,
                        diagnosticGeneration: data.diagnosticGeneration,
                        diagnosticFamily: data.diagnosticFamily,
                    },
                } as UpdateProductSoftwareApiArg;
                const result = await updateProductSoftware(
                    request,
                );

                if ('error' in result) {
                    return;
                }
            }
            handleCloseDialog();
        }
    };

    useEffect(() => {
        if (softwareProduct) {
            setValue('swAssy', softwareProduct.swAssy);
            setValue('swAssyVersion', softwareProduct.swAssyVersion);
            setValue('diagnosticFamily', softwareProduct.diagnosticFamily);
            setValue('diagnosticGeneration', softwareProduct.diagnosticGeneration);
        }
    }, [softwareProduct]);

    const renderContent = () => {
        if (isLoading) {
            return <>
                <ContentLoader className={'margin-top-25 margin-bottom-25'} height={30}/>
                <ContentLoader className={'margin-bottom-20'} height={30}/>
                <ContentLoader className={'margin-bottom-20'} height={30}/>
                <ContentLoader className={'margin-bottom-20'} height={30}/>
            </>;
        }

        return (
            <form>
                <Controller
                    control={control}
                    name="softwareId"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) =>
                        <div
                            className={classNames('form-group', errors.swAssy && 'has-feedback has-error padding-bottom-15')}>
                            <FormItem
                                label={'intl-msg:softwareProducts.softwareId'}
                                placeHolder={'intl-msg:softwareProducts.softwareId'}
                                value={value}
                                onChange={onChange}
                            />
                            {
                                errors.swAssy && <>
                                    <span className="form-control-feedback rioglyph rioglyph-error-sign"/>
                                    <span className="help-block">
                                        <span><FormattedMessage id="intl-msg:form:requiredField"/></span>
                                    </span>
                                </>
                            }
                        </div>
                    }
                />
                <Controller
                    control={control}
                    name="swAssy"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) =>
                        <div
                            className={classNames('form-group', errors.swAssy && 'has-feedback has-error padding-bottom-15')}>
                            <FormItem
                                label={'intl-msg:softwareProducts.swAssy'}
                                placeHolder={'intl-msg:softwareProducts.swAssy'}
                                value={value}
                                onChange={onChange}
                            />
                            {
                                errors.swAssy && <>
                                    <span className="form-control-feedback rioglyph rioglyph-error-sign"/>
                                    <span className="help-block">
                                        <span><FormattedMessage id="intl-msg:form:requiredField"/></span>
                                    </span>
                                </>
                            }
                        </div>
                    }
                />
                <Controller
                    control={control}
                    name="swAssyVersion"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) =>
                        <div
                            className={classNames('form-group', errors.swAssyVersion && 'has-feedback has-error padding-bottom-15')}>
                            <FormItem
                                label={'intl-msg:softwareProducts.swAssyVersion'}
                                placeHolder={'intl-msg:softwareProducts.swAssyVersion'}
                                value={value}
                                onChange={onChange}
                            />
                            {
                                errors.swAssyVersion && <>
                                    <span className="form-control-feedback rioglyph rioglyph-error-sign"/>
                                    <span className="help-block">
                                        <span><FormattedMessage id="intl-msg:form:requiredField"/></span>
                                    </span>
                                </>
                            }
                        </div>
                    }
                />
                <Controller
                    control={control}
                    name="diagnosticFamily"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) =>
                        <div
                            className={classNames('form-group', errors.diagnosticFamily && 'has-feedback has-error padding-bottom-15')}>
                            <FormItem
                                label={'intl-msg:softwareProducts.diagnosticFamily'}
                                placeHolder={'intl-msg:softwareProducts.diagnosticFamily'}
                                value={value}
                                onChange={onChange}
                            />
                            {
                                errors.diagnosticFamily && <>
                                    <span className="form-control-feedback rioglyph rioglyph-error-sign"/>
                                    <span className="help-block">
                                        <span><FormattedMessage id="intl-msg:form:requiredField"/></span>
                                    </span>
                                </>
                            }
                        </div>
                    }
                />
                <Controller
                    control={control}
                    name="diagnosticGeneration"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) =>
                        <div
                            className={classNames('form-group', errors.diagnosticGeneration && 'has-feedback has-error padding-bottom-15')}>
                            <FormItem
                                label={'intl-msg:softwareProducts.diagnosticGeneration'}
                                placeHolder={'intl-msg:softwareProducts.diagnosticGeneration'}
                                value={value}
                                onChange={onChange}
                            />
                            {
                                errors.diagnosticGeneration && <>
                                    <span className="form-control-feedback rioglyph rioglyph-error-sign"/>
                                    <span className="help-block">
                                        <span><FormattedMessage id="intl-msg:form:requiredField"/></span>
                                    </span>
                                </>
                            }
                        </div>
                    }
                />
            </form>
        );
    };

    return (
        <ConfirmationDialog
            show={show}
            title={softwareProduct ? <FormattedMessage id="intl-msg:softwareProducts.updateSoftwareProduct"/> :
                <FormattedMessage id="intl-msg:softwareProducts.createSoftwareProduct"/>}
            content={renderContent()}
            bsSize={Dialog.SIZE_MD}
            onClickCancel={handleCloseDialog}
            cancelButtonText={<FormattedMessage id="intl-msg:cancel"/>}
            onClickConfirm={handleSubmit(onSubmit)}
            disableConfirm={!isEmpty(errors) || isLoading}
            confirmButtonText={<FormattedMessage id="intl-msg:save"/>}
        />
    );
};
