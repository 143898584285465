import {
    BaseQueryApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import { RootState } from '~/setup/store';

export const restBaseQuery: any = (url: string) => {
    try {
        const baseQuery = fetchBaseQuery({
            baseUrl: url,
            paramsSerializer: (params: Record<string, unknown>) =>
                queryString.stringify(params, { arrayFormat: 'none' }),
            prepareHeaders: (headers, { getState }) => {
                const token = (getState() as RootState).tokenHandling.idToken;
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`);
                }
                return headers;
            },
        });

        return async(
            args: string | FetchArgs,
            api: BaseQueryApi,
            extraOptions: {},
        ) => {
            const { error, data } = await baseQuery(args, api, extraOptions);
            if (error) {
                // return handleError(error);
            }
            return { data };
        };
    } catch (error: any) {
        // return handleError(error);
    }
};
