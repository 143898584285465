// Generic paths
export const LIST_PATH = 'list';
export const DETAILS_PATH = 'details';

// Primary paths
export const OVERVIEW_PATH = 'overview';
export const DEVICES_PATH = 'devices';

export const GROUPS_PATH = 'groups';
export const FILES_PATH = 'files';
export const CM4G_PATH = 'cm4g';
export const BUNDLE_PATH = 'bundles';
export const DELIVERABLES_MANAGEMENT_PATH = 'deliverables';
export const SOFTWARE_PRODUCTS_PATH = 'softwareProducts';

// Secondary paths
export const HOME_PATH = 'home';
export const SYSTEM_STATE_NOTIFICATIONS_PATH = 'state';
export const WHITELIST_PATH = 'whitelist';
export const HISTORY_PATH = 'history';
export const DELIVERABLES_PATH = 'deliverables';
export const DELIVERABLES_FILES_PATH = 'files';
export const DELIVERABLES_CM4G_PATH = 'cm4g';
export const DELIVERABLES_BUNDLES_PATH = 'bundles';
export const EVENTS_PATH = 'events';
export const LOG_REQUESTS_PATH = 'log-requests';
export const DEVICES_ASSIGNED_PATH = 'devices-assigned';
export const REFERENCES_PATH = 'references';
