import React from 'react';

interface SelectionTableHeaderProps {
    allowSelection?: boolean;
}

// TODO Think about killing this if there's nothing more to it
export const SelectionTableHeader = ({ allowSelection = false }: SelectionTableHeaderProps) => {
    if (!allowSelection) {
        return null;
    }
    return (
        <th>
        </th>
    );
};

export default SelectionTableHeader;
