import React from 'react';
import ShortBaseSwVersion from '~/features/baseSwVersions/components/ShortBaseSwVersion';
import DeviceRolloutConfirmation from "~/features/devices/components/DeviceRolloutConfirmation";
import {updateEventType, updateOutcome} from '~/features/devices/constants/deviceRolloutStates';
import classNames from 'classnames';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import {FormattedMessage, FormattedTime} from 'react-intl';

interface DeviceRolloutStateHeaderProps {
    rolloutState
}

const DeviceRolloutStateHeader: React.FC<DeviceRolloutStateHeaderProps> = ({rolloutState}: DeviceRolloutStateHeaderProps) => {
    const renderedUpdateOutcome = renderOutcome(rolloutState);
    const labelClass = updateEventType[rolloutState.type].className;
    return (
        <div>
            <div className='row'>
                <span>
                    {renderedUpdateOutcome}
                </span>
                <span className='text-color-dark padding-left-5'>
                        <FormattedTime value={rolloutState.lastUpdated} year='numeric' month='2-digit' day='2-digit'/>
                </span>
            </div>
            <div className='row padding-top-5 padding-left-25 justify-content-center'>
                <div className='display-flex align-items-baseline'>
                    <div>
                            <span className={classNames('label label-condensed margin-right-5',
                                labelClass)}>
                                <FormattedMessage id={updateEventType[rolloutState.type].label}/>
                            </span>
                    </div>
                    <div>
                        <span className='text-color-dark'>{`${rolloutState.deliverableId} - v.`}</span>
                        <span className='text-color-dark'>
                            <ShortBaseSwVersion baseSwVersion={rolloutState.version}/>
                        </span>
                    </div>
                    <div className='margin-left-5'>
                        <DeviceRolloutConfirmation eventSteps={rolloutState.eventSteps}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

const renderOutcome = (rolloutState) => {
    if (!rolloutState.outcome) {
        return;
    }
    const labelClass = updateOutcome[rolloutState.outcome].className;
    return (
        <span>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip' className='top-right'>
                        <FormattedMessage id={updateOutcome[rolloutState.outcome].tooltip}/>
                    </Tooltip>}>
                    <span className={classNames('text-size-18', labelClass)}></span>
                </OverlayTrigger>
            </span>
    );
};

export default DeviceRolloutStateHeader;

