import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import find from 'lodash/fp/find';
import {
    CM4G_PATH,
    DELIVERABLES_MANAGEMENT_PATH,
    DEVICES_PATH,
    GROUPS_PATH,
    OVERVIEW_PATH,
    SOFTWARE_PRODUCTS_PATH,
} from '~/features/base/constants/routes';
import SidebarRoutes from '~/features/base/components/SidebarRoutes';
import { accessToken } from '~/features/tokenHandling/accessToken';

import DeviceDetailsSidebarRoutes from '~/features/devices/components/DeviceDetailsSidebarRoutes';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import PermissionsOverviewDialog from '~/features/base/components/dialogs/PermissionsOverviewDialog';
import { connect } from 'react-redux';
import noop from 'lodash/fp/noop';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import { showDeliverablePermissionsDialog } from '~/features/deliverables/actions/deliverableActions';

const navItems = [{
    key: 'overview',
    route: (
        <NavLink to={`/${OVERVIEW_PATH}`}>
            <FormattedMessage id="intl-msg:subModuleName.overview"/>
        </NavLink>
    ),
}, {
    key: 'devices',
    route: (
        <NavLink to={`/${DEVICES_PATH}`}>
            <FormattedMessage id="intl-msg:subModuleName.devices"/>
        </NavLink>
    ),
}, {
    key: 'groups',
    route: (
        <NavLink to={`/${GROUPS_PATH}`}>
            <FormattedMessage id="intl-msg:subModuleName.groups"/>
        </NavLink>
    ),
}, {
    key: 'deliverables',
    route: (
        <NavLink to={`/${DELIVERABLES_MANAGEMENT_PATH}/${CM4G_PATH}`}>
            <FormattedMessage id="intl-msg:deliverables"/>
        </NavLink>
    ),
}, {
    key: 'software',
    route: (
        <NavLink to={`/${SOFTWARE_PRODUCTS_PATH}`}>
            <FormattedMessage id="intl-msg:softwareProducts"/>
        </NavLink>
    ),
}];

const appTitle = <FormattedMessage id="intl-msg:app.title"/>;

/**
 * Container for a device management submodule
 */
export class ApplicationFrame extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPermissionsOverview: false,
        };
    }

    containsNavItem = (itemName) => {
        return find(navItem => {
            return navItem.key === itemName;
        }, navItems) !== undefined;
    };

    togglePermissionsOverDialog = () => {
        this.setState({
            ...this.state,
            showPermissionsOverview: !this.state.showPermissionsOverview,
        });
    };

    renderPermissionsOverDialog = () => {
        return (
            <PermissionsOverviewDialog togglePermissionsOverDialog={this.togglePermissionsOverDialog}/>
        );
    };

    render() {
        const {
            userLocale,
            showSessionExpired,
            children,
            onHideSessionDialog,
        } = this.props;

        const actionBarItems = [
            <ActionBarItem id="userMenu">
                <ActionBarItem.Icon>
                    <span className="icon rioglyph rioglyph rioglyph-cards-list"></span>
                </ActionBarItem.Icon>
                <ActionBarItem.Popover useOffscreen>
                    <h6>Info</h6>
                    {/*<div className='padding-left-15'>*/}
                    {/*    <span className='rioglyph rioglyph-info-sign text-primary'></span>*/}
                    {/*    <span className='text-primary'>{'  Build: 23-12-2020:12:04'}</span>*/}
                    {/*</div>*/}
                    <div>
                        <a href="https://confluence.collaboration-man.com/display/RIOCLAID/MDM+OTA+Documentation"
                           target="_blank">
                            <button type="button" className="btn btn-link">
                                <span className="rioglyph rioglyph-detail-view-info" aria-hidden="true"></span>
                                <span className="text-primary" aria-hidden="true">
                                    <FormattedMessage id="intl-msg:otaDocumentation"/>
                                </span>
                            </button>
                        </a>
                    </div>
                    <div>
                        <a href="https://confluence.collaboration-man.com/display/MDM/MDM+Knowledge+Base"
                           target="_blank">
                            <button type="button" className="btn btn-link">
                                <span className="rioglyph rioglyph-detail-view-info" aria-hidden="true"></span>
                                <span className="text-primary" aria-hidden="true">
                                    <FormattedMessage id="intl-msg:otaKnowledgeBase"/>
                                </span>
                            </button>
                        </a>
                    </div>
                    <h6>User</h6>
                    <div>
                        <button type="button" className="btn btn-link"
                                onClick={() => this.togglePermissionsOverDialog()}>
                            <span className="rioglyph rioglyph-user-sign" aria-hidden="true"></span>
                            <span className="text-primary" aria-hidden="true">
                                <FormattedMessage id="intl-msg:permissionsApplication"/>
                            </span>
                        </button>
                        <button type="button" className="btn btn-link"
                                onClick={this.props.onDeliverablePermissionsDialog}>
                            <span className="rioglyph rioglyph-user-sign" aria-hidden="true"></span>
                            <span className="text-primary" aria-hidden="true">
                                <FormattedMessage id="intl-msg:managePermissionsDeliverable"/>
                            </span>
                        </button>
                        <button type="button" className="btn btn-link"
                                onClick={() => copyToClipboard(`Bearer ${accessToken.getAccessToken()}`, false)}>
                            <span className="rioglyph rioglyph-duplicate" aria-hidden="true"></span>
                            <span className="text-primary" aria-hidden="true">
                                <FormattedMessage id="intl-msg:copyAccessToken"/>
                            </span>
                        </button>
                    </div>
                    <div
                        className="border-style-solid border-width-1 border-color-lighter border-top-only margin-left-10 margin-top-20 padding-top-10"></div>
                </ActionBarItem.Popover>
            </ActionBarItem>,
        ];

        return (
            <ApplicationLayout>
                {this.state.showPermissionsOverview && this.renderPermissionsOverDialog()}
                <ApplicationLayout.Header>
                    <ApplicationHeader
                        label={appTitle}
                        navItems={navItems}
                        actionBarItems={actionBarItems}/>
                </ApplicationLayout.Header>
                <ApplicationLayout.Sidebar className="bg-lighter">
                    <SidebarRoutes/>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Sidebar className="right">
                    <DeviceDetailsSidebarRoutes/>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body className="responsive">
                    <NotificationsContainer/>
                    <SessionExpiredDialog
                        locale={userLocale}
                        onClose={onHideSessionDialog}
                        show={showSessionExpired}/>
                    {children}
                </ApplicationLayout.Body>
            </ApplicationLayout>
        );
    }
}

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => {
    return {
        onDeliverablePermissionsDialog: () => {
            dispatch(showDeliverablePermissionsDialog());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFrame);

ApplicationFrame.propTypes = {
    // props
    accessToken: PropTypes.string,
    homeRoute: PropTypes.string,
    appMenuUri: PropTypes.string,
    userMenuUri: PropTypes.string,
    userLocale: PropTypes.string,
    showSessionExpired: PropTypes.bool,
    // functions
    onHideSessionDialog: PropTypes.func,
    onDeliverablePermissionsDialog: noop,
};
