import { useSoftwareProducts } from '~/api/softwareProducts/useSoftwareProducts';
import { ProductSoftwareSearchCriteria } from '~/codegen/softwareProducts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/setup/store';
import { useAppSelector } from '~/setup/hooks';

export const SOFTWARE_PRODUCTS_TABLE_PAGE_SIZE = 50;

type SoftwareProductFilterState = {
    softwareId: string,
    swAssy: string,
    swAssyVersion: string,
};

const initialState: SoftwareProductFilterState = {
    softwareId: '',
    swAssy: '',
    swAssyVersion: '',
};

export const softwareProductFilterSlice = createSlice({
    name: 'softwareProductFilter',
    initialState,
    reducers: {
        setSoftwareProductId: (state, action: PayloadAction<string>) => {
            state.softwareId = action.payload;
        },
        setSoftwareProductSwAssy: (state, action: PayloadAction<string>) => {
            state.swAssy = action.payload;
        },
        setSoftwareProductSwAssyVersion: (state, action: PayloadAction<string>) => {
            state.swAssyVersion = action.payload;
        },
        resetSoftwareProductFilter: () => {
            return initialState;
        },
    },
});

export const {
    setSoftwareProductId,
    setSoftwareProductSwAssy,
    setSoftwareProductSwAssyVersion,
    resetSoftwareProductFilter,
} = softwareProductFilterSlice.actions;

export const getSoftwareProductIdFilter = (state: RootState) => state.softwareProductFilter.softwareId;
export const getSoftwareProductSwAssyFilter = (state: RootState) => state.softwareProductFilter.swAssy;
export const getSoftwareProductSwAssyVersionFilter = (state: RootState) => state.softwareProductFilter.swAssyVersion;

export const useFilteredSoftwareProducts = () => {
    const softwareId = useAppSelector(getSoftwareProductIdFilter);
    const swAssy = useAppSelector(getSoftwareProductSwAssyFilter);
    const swAssyVersion = useAppSelector(getSoftwareProductSwAssyVersionFilter);
    const page = 0;
    const size = SOFTWARE_PRODUCTS_TABLE_PAGE_SIZE;
    const productSoftwareSearchCriteria = {
        softwareId: softwareId || undefined,
        swAssy: swAssy || undefined,
        swAssyVersion: swAssyVersion || undefined,
    } as ProductSoftwareSearchCriteria;

    const {
        softwareProducts,
        error,
        isLoading,
    } = useSoftwareProducts(
        page,
        size,
        productSoftwareSearchCriteria,
    );

    return {
        softwareProducts,
        error,
        isLoading,
    };
};
