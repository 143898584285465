import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SoftwareProductsFilter } from '~/features/softwareProduct/components/SoftwareProductsFilter';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import { SoftwareProductsTable } from '~/features/softwareProduct/components/SoftwareProductsTable';
import { SoftwareProductDialog } from '~/features/softwareProduct/components/SoftwareProductDialog';
import { useFilteredSoftwareProducts } from '~/api/softwareProducts/softwareProductsFilterSlice';
import { ProductSoftwareDto } from '~/codegen/softwareProducts';

export const SoftwareProductPage: React.FC = () => {
    const [showCreateDialog, setCreateShowDialog] = useState(false);
    const { softwareProducts, isLoading } = useFilteredSoftwareProducts();
    const productSoftwareDtos: ProductSoftwareDto[] | undefined = softwareProducts || [];

    return (
        <div>
            <SoftwareProductDialog show={showCreateDialog} onClose={() => setCreateShowDialog(false)}/>
            <h1><FormattedMessage id="intl-msg:softwareProducts"/></h1>
            <DefaultWhiteColumn key={'softwareProductList'}
                                className="padding-top-20 padding-bottom-5 user-select-none">
                <SoftwareProductsFilter/>
                <div className="display-flex row justify-content-end margin-25">
                    <button onClick={() => setCreateShowDialog(true)}>
                        <FormattedMessage id="intl-msg:softwareProducts.createNew"/>
                    </button>
                </div>
                <SoftwareProductsTable isLoading={isLoading} productSoftwareDtos={productSoftwareDtos}/>
            </DefaultWhiteColumn>
        </div>
    );
};
