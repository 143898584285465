import React from 'react';
import {ASSIGNED} from "~/features/devices/constants/deviceRolloutStates";
import Tooltip from "@rio-cloud/rio-uikit/lib/es/Tooltip";
import {FormattedMessage} from 'react-intl';
import OverlayTrigger from "@rio-cloud/rio-uikit/lib/es/OverlayTrigger";
import classNames from "classnames";
import {RolloutProgressStep} from "~/api/models/rollout-progress-step";
import {RolloutState} from "~/api/models/rollout-state";

export const confirmationRolloutStatus = {
    [RolloutState.CONFIRMATION_PENDING]: {
        id: ASSIGNED,
        label: 'intl-msg:deviceRolloutStatus.confirmationPending',
        tooltip: 'intl-msg:deviceRolloutStatus.confirmationPending.tooltip',
        className: 'text-color-warning'
    },
    [RolloutState.INSTALLATION_IN_PROGRESS]: {
        id: ASSIGNED,
        label: 'intl-msg:deviceRolloutStatus.installationAccepted',
        tooltip: 'intl-msg:deviceRolloutStatus.installationAccepted.tooltip',
        className: 'text-color-success'
    }
};

interface DeviceRolloutConfirmationProps {
    eventSteps: RolloutProgressStep[]
}

const confirmationOverlayTrigger = (attributes) => {
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={attributes.tooltip}/>
            </Tooltip>}>
            <div className="flex-wrap gap-5 align-items-baseline">
                <span className={classNames('rioglyph rioglyph-driver text-size-h4', attributes.className)}/>
                <span className={classNames('text-size-h6', attributes.className)}>
                    <FormattedMessage id={attributes.label}/>
                </span>
            </div>
        </OverlayTrigger>
    )
}

const DeviceRolloutConfirmation: React.FC<DeviceRolloutConfirmationProps> = ({eventSteps}: DeviceRolloutConfirmationProps) => {
    const isInstallationAccepted = eventSteps?.some(eventStep => eventStep.state === RolloutState.INSTALLATION_IN_PROGRESS);
    const isConfirmationPending = eventSteps?.some(eventStep => eventStep.state === RolloutState.CONFIRMATION_PENDING);

    if (isInstallationAccepted) {
        return confirmationOverlayTrigger(confirmationRolloutStatus[RolloutState.INSTALLATION_IN_PROGRESS]);
    }

    if (isConfirmationPending) {
        return confirmationOverlayTrigger(confirmationRolloutStatus[RolloutState.CONFIRMATION_PENDING]);
    }

    return (<span></span>)
};

export default DeviceRolloutConfirmation;

