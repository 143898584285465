import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { dynamicLogBaseQuery } from '~/api/common/dynamicBaseQuery';
import { IncomingLogsRequestDto } from '~/api/models/IncomingLogsRequestDto';
import { LogLevelRequestDto } from '~/api/models/LogLevelRequestDto';
import { LogsSearchCriteria } from '~/api/models/logs-search-criteria';
import { MultiselectOption } from '@rio-cloud/rio-uikit/types';
import { PageQuery } from '~/api/models/PageQuery';
import { PagePersistedLogsRequest } from '~/api/models/pagePersistedLogsRequest';

export const LOG_TAG = 'Logs';
export const loggingServiceApi = createApi({
    reducerPath: 'logsApi',
    baseQuery: dynamicLogBaseQuery,
    tagTypes: [LOG_TAG],
    endpoints: (builder) => ({
        requestLogs: builder.mutation<{}, IncomingLogsRequestDto>({
            query: (LogParams: IncomingLogsRequestDto) => ({
                url: `/v1/logs/request-logs`,
                method: 'POST',
                body: LogParams,
            }),
            invalidatesTags: [LOG_TAG],
        }),
        fetchLogs: builder.query<PagePersistedLogsRequest, LogsSearchCriteria & PageQuery>({
            query: (criteria: LogsSearchCriteria & PageQuery) => ({
                url: `/v1/logs/request-logs/search?page=${criteria.pageNumber}&size=${criteria.pageSize}`,
                method: 'POST',
                body: criteria,
            }),
            serializeQueryArgs: ({ queryArgs: { pageNumber, pageSize, ...rest } }) => {
                return JSON.stringify({ ...rest });
            },
            merge: (currentCache, newItems) => {
                if (newItems.number != 0) {
                    const currentCacheAux = [...currentCache.content, ...newItems.content];
                    currentCache.content = [...new Map(currentCacheAux.map(item => [item.id, item])).values()];
                } else {
                    currentCache.content = newItems.content;
                }
            },
            providesTags: [LOG_TAG],
        }),
        fetchSystems: builder.query<MultiselectOption[], string>({
            query: (deviceType: string) => ({
                url: `/v1/logs/device-systems/${deviceType}`,
                method: 'GET',
            }),
            transformResponse: (systems: string[]) => {
                return systems.map(system => ({
                    id: system,
                    label: system,
                    selected: false,
                }));
            }
        }),
        setLogLevel: builder.mutation<{}, LogLevelRequestDto>({
            query: (LogParams: LogLevelRequestDto) => ({
                url: `/v1/configuration/log-level`,
                method: 'POST',
                body: LogParams,
            }),
        }),
        downloadLog: builder.query<string, string>({
            query: (operationId: string) => ({
                url: `/v1/logs/contents/${operationId}/download-url`,
                method: 'GET',
                responseHandler: 'text',

            }),
        }),
        downloadLogZip: builder.query<string, string>({
            query: (contentId: string) => ({
                url: `/v1/logs/contents/${contentId}/download-zip-url`,
                method: 'GET',
                responseHandler: 'text',

            }),
        }),
    })
});

export const {
    useRequestLogsMutation,
    useFetchLogsQuery,
    useFetchSystemsQuery,
    useSetLogLevelMutation,
    useLazyDownloadLogQuery,
    useLazyDownloadLogZipQuery,
} = loggingServiceApi;
