import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const toPackageIdAndVersion = (packageId, shortAppVersion) => `${packageId}@${shortAppVersion}`;

export const parsePackageDeliverable = ({ deliverableId, deliverableVersion, ...rest }) => {
    const shortPackageVersion = toShortSemanticVersion(deliverableVersion);
    const result = {
        packageIdAndVersion: toPackageIdAndVersion(deliverableId, shortPackageVersion),
        packageId: deliverableId,
        deliverableVersion,
        shortPackageVersion,
        ...rest,
    };
    return result;
};

export const parseDeliverableVersions = ({ deliverableVersions }) => {
    const allDeliverableVersions = [];
    deliverableVersions.forEach(v => {
        allDeliverableVersions.push(toShortSemanticVersion(v));
    });
    return allDeliverableVersions;
};

