import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import TableHeader from './TableHeader';
import SelectionTableHeader from './SelectionTableHeader';
import { sortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import isEmpty from 'lodash/fp/isEmpty';
import NoData from '~/features/base/components/NoData';

// table-layout-fixed table-bordered
const tableClassName = 'table table-hover table-column-overflow-hidden table-head-filled table-sticky';

export const ACTIVE_CLASS = 'active';
export const DATA_ATTRIBUTE = 'data-key';

/**
 * Table component for lists
 */
export class ListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRowId: '',
            lastActiveRowId: '',
            sortBy: props.sortBy || '',
            sortDir: props.sortDir || SortDirection.ASCENDING,
        };
    }

    getSortDir = (sortBy, previousSortBy) => {
        if (sortBy === previousSortBy) {
            return this.state.sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
        }
        return SortDirection.ASCENDING;
    };

    handleSortChange = (event) => {
        if (this.props.allowSorting) {
            const sortBy = event.currentTarget.getAttribute('data-sortby');
            this.setState({
                sortBy,
                sortDir: this.getSortDir(sortBy, this.state.sortBy),
            });
        }
    };

    render() {
        const { sortBy, sortDir } = this.state;
        const { items, className, columnDescriptors, isLoading, showHeader, searchValue } = this.props;
        if (!items) {
            return <FormattedMessage id="intl-msg:notFound"/>;
        }

        // in case a search value is given, filter the data accordingly
        const searchResult = !searchValue ? items : items.filter(row => (
            columnDescriptors.some(col => {
                try {
                    const rowColumnId = row[col.id];
                    return rowColumnId.toString().toLowerCase().includes(searchValue.toString().toLowerCase());
                } catch (e) {
                    return false;
                }
            })
        ));

        // Sort rows according to the sortBy and sortDir settings
        const rows = sortBy ? sortByProperty(searchResult, sortBy, sortDir) : searchResult;

        const tableHead = this.renderTableHead(columnDescriptors, sortBy, sortDir);
        const tableBody = this.renderTableBody(rows, isLoading);
        return (
            <table ref={(node) => (this.tableRef = node)} className={classNames(tableClassName, className)}>
                {showHeader && tableHead}
                {tableBody}
            </table>
        );
    }

    renderTableHead(columnDescriptors, sortBy, sortDir) {
        const { allowSelection } = this.props;
        const selectionTableHeader = <SelectionTableHeader allowSelection={allowSelection}/>;
        const tableHeaders = map((columnDescriptor) => {
            if (columnDescriptor.hidden) {
                return;
            }
            return <TableHeader key={columnDescriptor.id} columnDescriptor={columnDescriptor} sortBy={sortBy}
                                sortDir={sortDir}
                                handleSortChange={this.handleSortChange}/>;
        }, columnDescriptors);
        return (
            <thead className="table-head">
            <tr key={'tableHeadRow'}>
                {selectionTableHeader}
                {tableHeaders}
            </tr>
            </thead>
        );
    }

    renderTableBody(items, isLoading) {
        if (isEmpty(items) && !isLoading) {
            return <tr>
                <td colspan={7}>
                    <NoData text="No data"/>
                </td>
            </tr>;
        }
        const tableRows = isLoading ?
            <tr>
                <td colspan={7}>
                    <ContentLoader/>
                    <ContentLoader/>
                    <ContentLoader/>
                </td>
            </tr>
            : map(this.renderTableRow, items);
        return (
            <tbody>
            {tableRows}
            </tbody>
        );
    }

    renderTableRow = (item) => {
        if (!item) {
            return;
        }
        const { itemKey, columnDescriptors } = this.props;
        const selectionTableData = this.renderSelectionTableData(item);
        const tableData = map(columnDescriptor => this.renderTableData(item, columnDescriptor), columnDescriptors);
        const key = item[itemKey];
        return (
            <tr key={key} data-key={key} onClick={this.onRowClick}>
                {selectionTableData}
                {tableData}
            </tr>
        );
    };

    renderSelectionTableData(item) {
        const { selectedAll, selectedItems, allowSelection, itemKey } = this.props;
        const selected = selectedAll || includes(item[itemKey], selectedItems);
        if (allowSelection) {
            return (
                <td key={`td-${itemKey}`} className="selection">
                    <Checkbox checked={selected}/>
                </td>
            );
        }
    }

    renderTableData = (item, columnDescriptor) => {
        if (columnDescriptor.hidden) {
            return;
        }
        return (
            <td key={`td-${columnDescriptor.id}`} className={classNames(columnDescriptor.className)}>
                {
                    columnDescriptor.format ?
                        columnDescriptor.format(get(columnDescriptor.field, item), item) :
                        item[columnDescriptor.field]
                }
            </td>
        );
    };

    onRowClick = (event) => {
        if (this.props.preventRowClickEventPropagation) {
            event.preventDefault();
            event.stopPropagation();
        }

        const { activeRowId } = this.state;
        const rowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);

        this.setState(() => ({
            lastActiveRowId: activeRowId,
            activeRowId: activeRowId === rowId ? '' : rowId,
        }));

        const rows = [...this.tableRef.rows];
        if (this.props.highlightSelectedRow) {
            this.highlightRow(rows, rowId);
            this.removeHighlightFromRow(rows, activeRowId);
        }
        return this.props.onRowClick(rowId, activeRowId !== rowId);
    };

    getRowByDataAttribute = (rows = [], value = '', attribute = 'data-key') =>
        rows.find((row) => {
            const dataAttribute = row.attributes[attribute];
            if (dataAttribute) {
                return dataAttribute.value === value;
            }
            return false;
        });

    highlightRow = (rows, activeRowId) => {
        const row = this.getRowByDataAttribute(rows, activeRowId);
        if (row) {
            row.classList.add(ACTIVE_CLASS);
        }
    };

    removeHighlightFromRow = (rows, lastActiveRowId) => {
        const row = this.getRowByDataAttribute(rows, lastActiveRowId);
        if (row) {
            row.classList.remove(ACTIVE_CLASS);
        }
    };
}

export default ListTable;

ListTable.defaultProps = {
    // props
    columnDescriptors: [],
    itemKey: 'id',
    searchValue: '',
    allowSelection: false,
    isLoading: false,
    allowSorting: false,
    selectedItems: [],
    selectedAll: false,
    highlightSelectedRow: false,
    showHeader: true,
    preventRowClickEventPropagation: true,
    // functions
    onRowClick: noop,
    onSelectItem: noop,
    onSelectAll: noop,
    onShowItem: noop,
};

ListTable.propTypes = {
    // props
    className: PropTypes.string,
    items: PropTypes.array,
    searchValue: PropTypes.string,
    itemKey: PropTypes.string,
    columnDescriptors: PropTypes.array,
    allowSelection: PropTypes.bool,
    isLoading: PropTypes.bool,
    allowSorting: PropTypes.bool,
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    highlightSelectedRow: PropTypes.bool,
    showHeader: PropTypes.bool,
    preventRowClickEventPropagation: PropTypes.bool,
    // functions
    onRowClick: PropTypes.func,
    onSelectItem: PropTypes.func,
    onSelectAll: PropTypes.func,
    onShowItem: PropTypes.func,
};
