export enum RolloutState {
    ASSIGNED = 'ASSIGNED',
    SENT_TO_DEVICE = 'SENT_TO_DEVICE',
    DOWNLOAD_IN_PROGRESS = 'DOWNLOAD_IN_PROGRESS',
    DOWNLOAD_FINISHED = 'DOWNLOAD_FINISHED',
    DOWNLOAD_TIMED_OUT = 'DOWNLOAD_TIMED_OUT',
    CONFIRMATION_PENDING = 'CONFIRMATION_PENDING',
    INSTALLATION_IN_PROGRESS = 'INSTALLATION_IN_PROGRESS',
    INSTALLATION_FINISHED = 'INSTALLATION_FINISHED',
    REBOOT_PENDING = 'REBOOT_PENDING',
    PAUSED = 'PAUSED',
    ABORTED = 'ABORTED',
    FAILED = 'FAILED',
    FINISHED = 'FINISHED'
}