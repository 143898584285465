import React from 'react';

export const softwareProductColumnDescriptor = [
    {
        id: 'softwareId',
        field: 'softwareId',
        label: 'intl-msg:softwareProducts.softwareId',
    },
    {
        id: 'swAssy',
        field: 'swAssy',
        label: 'intl-msg:softwareProducts.swAssy',
    },
    {
        id: 'swAssyVersion',
        field: 'swAssyVersion',
        label: 'intl-msg:softwareProducts.swAssyVersion',
    },
    {
        id: 'diagnosticFamily',
        field: 'diagnosticFamily',
        label: 'intl-msg:softwareProducts.diagnosticFamily',
    },
    {
        id: 'diagnosticGeneration',
        field: 'diagnosticGeneration',
        label: 'intl-msg:softwareProducts.diagnosticGeneration',
    },
    {
        id: 'ivd',
        field: 'ivd',
        label: 'intl-msg:softwareProducts.ivd',
        format: field => {
            return <>
                <span>{field && field[1]}</span>
            </>;
        },
    },
];
