import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {history, store} from '~/setup/store';
import {env} from '~/env';
import IntlContainer from '~/features/base/components/IntlContainer';
import MainContainer from '~/features/base/components/MainContainer';
import {NoMatch} from '~/features/base/components/NoMatch';

import '~/style/css/main.less'; // TODO Maybe move away to actual app
import TabVisibilitySensor from './features/base/components/TabVisibilitySensor';
import {main} from '~/setup';
import {handleLoginRedirect} from '~/features/login/redirect';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root')!;
const root = createRoot(container);

const renderApplication = () => {
    return root.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <TabVisibilitySensor/>
                <IntlContainer>
                    <Switch>
                        <Route path='/error' component={NoMatch}/>
                        <Route path='/' component={MainContainer}/>
                        <Route component={NoMatch}/>
                    </Switch>
                </IntlContainer>
            </ConnectedRouter>
        </Provider>,
    );
};


if (window.location.href.startsWith(env.runtimeConfig.login.redirectUri)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
